<template>
    <div class="con-box">
        <div style="display: flex;justify-content: space-between;">
            <div class="con-box" style="width: 49%;margin-top: 0;">
                <div class="title">
                    <p style="width: calc(100% / 3);text-aling: center;">资产</p>
                    <p style="width: calc(100% / 3);text-aling: center;">期末余额</p>
                    <p style="width: calc(100% / 3);text-aling: center;">年初余额</p>
                </div>
                <div class="content-box" v-for="(aItem, ai) in assetList" :key="ai">
                    <div style="display: flex; width: 100%;" :style="{fontWeight:(aItem.ASSET_COLUMN==='流动资产：'||aItem.ASSET_COLUMN==='非流动资产：')?'bold':''}"  >
                        <a-form-item  :label="aItem.ASSET_COLUMN" :style="{fontSize:(aItem.ASSET_COLUMN==='流动资产：'||aItem.ASSET_COLUMN==='非流动资产：')?'15px':'12px'}" style="width: 66%; " >
                            <a-input-number  v-if="(aItem.ASSET_COLUMN==='流动资产：'||aItem.ASSET_COLUMN==='非流动资产：')?false:true" v-model:value="aItem.ASSET_ENDING_BALANCE" :disabled="(aItem.ASSET_COLUMN === '非流动资产：' || aItem.ASSET_COLUMN === '流动资产：' || !isEdit) ? true : false"  :placeholder="(aItem.ASSET_COLUMN === '非流动资产：' || aItem.ASSET_COLUMN === '流动资产：')? '' : '请输入期末余额'"/>
                        </a-form-item>
                        <a-form-item style="margin-left: 20px;">
                            <a-input-number v-if="(aItem.ASSET_COLUMN==='流动资产：'||aItem.ASSET_COLUMN==='非流动资产：')?false:true" v-model:value="aItem.ASSET_BEGINNING_BALANCE" :disabled="(aItem.ASSET_COLUMN === '非流动资产：' || aItem.ASSET_COLUMN === '流动资产：'  || !isEdit) ? true : false"  :placeholder="(aItem.ASSET_COLUMN === '非流动资产：' || aItem.ASSET_COLUMN === '流动资产：')? '' : '请输入年初余额'"/>
                        </a-form-item>
                    </div>
                </div>
            </div>
            <div class="con-box" style="width: 49%;margin-top: 0;">
                <div class="title">
                    <p style="width: calc(100% / 3);text-aling: center;font-size: 14px;">负债和所有者权益(或股东权益)</p>
                    <p style="width: calc(100% / 3);text-aling: center;">期末余额</p>
                    <p style="width: calc(100% / 3);text-aling: center;">年初余额</p>
                </div>
                <div class="content-box" v-for="(dItem, di) in debtList" :key="di">
                    <div :style="{fontWeight: (dItem.DEBT_COLUMN==='流动负债：'||dItem.DEBT_COLUMN==='非流动负债：'||dItem.DEBT_COLUMN==='所有者权益（或股东权益）：')?'bold':''}" style="display: flex;width: 100%; ">
                        <a-form-item  :label="dItem.DEBT_COLUMN"   :style="{fontSize:(dItem.DEBT_COLUMN==='流动负债：'||dItem.DEBT_COLUMN==='非流动负债：'||dItem.DEBT_COLUMN==='所有者权益（或股东权益）：')?'15px':'12px'}" style="width: 66%;" v-if="dItem.DEBT_COLUMN==='...:'?false:true"  >
                            <a-input-number  v-if="(dItem.DEBT_COLUMN==='流动负债：'||dItem.DEBT_COLUMN==='非流动负债：'||dItem.DEBT_COLUMN==='所有者权益（或股东权益）：'|| dItem.DEBT_COLUMN === '...:')?false:true"  size="100px" v-model:value="dItem.DEBT_ASSET_PROPERTY" :disabled="(dItem.DEBT_COLUMN === '非流动负债：' || dItem.DEBT_COLUMN === '流动负债：' || dItem.DEBT_COLUMN === '...:'  || !isEdit) ? true : false" :placeholder="(dItem.DEBT_COLUMN === '非流动负债：' || dItem.DEBT_COLUMN === '流动负债：' || dItem.DEBT_COLUMN === '...:') ? '' : '请输入期末余额'"/>
                        </a-form-item>
                        <a-form-item style="margin-left: 20px;">
                            <a-input-number  v-if="(dItem.DEBT_COLUMN==='流动负债：'||dItem.DEBT_COLUMN==='非流动负债：'||dItem.DEBT_COLUMN==='所有者权益（或股东权益）：'|| dItem.DEBT_COLUMN === '...:')?false:true" v-model:value="dItem.DEBT_ENDING_BALANCE" :disabled="(dItem.DEBT_COLUMN === '非流动负债：' || dItem.DEBT_COLUMN === '流动负债：' || dItem.DEBT_COLUMN === '...:'  || !isEdit) ? true : false" :placeholder="(dItem.DEBT_COLUMN === '非流动负债：' || dItem.DEBT_COLUMN === '流动负债：' || dItem.DEBT_COLUMN === '...:') ? '' : '请输入年初余额'"/>
                        </a-form-item>
                    </div>
                </div>
            </div>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" @click="onSave">保存当前</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'isEdit',
            'y'
        ])
    },
    data () {
        return {
            assetList: [],
            debtList: []
        }
    },
    watch: {
        y: function (newVal) {
            this.getZCFZList(newVal)
        }
    },
    methods: {
        // 获取资产负债列表
        getZCFZList (y) {
            this.$store.dispatch('getZCFZList', { ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime(), showCount: 50, currentPage: 1 }).then(res => {
                this.assetList = []
                this.debtList = []
                if (res.result === 'success') {
                    let datas = res.varList
                    for (let i in datas) {
                        this.assetList.push({
                            ASSET_COLUMN: datas[i].ASSET_COLUMN,
                            ASSET_PROPERTY: datas[i].ASSET_PROPERTY,
                            ASSET_ENDING_BALANCE: (datas[i].ASSET_COLUMN === '流动资产：' || datas[i].ASSET_COLUMN === '非流动资产：') ? '' : (datas[i].ASSET_ENDING_BALANCE !== '' && datas[i].ASSET_ENDING_BALANCE) ? datas[i].ASSET_ENDING_BALANCE : '',
                            ASSET_BEGINNING_BALANCE: (datas[i].ASSET_COLUMN === '流动资产：' || datas[i].ASSET_COLUMN === '非流动资产：') ? '' : (datas[i].ASSET_BEGINNING_BALANCE !== '' && datas[i].ASSET_BEGINNING_BALANCE) ? datas[i].ASSET_BEGINNING_BALANCE : '',
                            ENTERZICHANFUZHAI_ID: datas[i].ENTERZICHANFUZHAI_ID
                        })
                        this.debtList.push({
                            DEBT_COLUMN: datas[i].DEBT_COLUMN,
                            DEBT_PROPERTY: datas[i].DEBT_PROPERTY,
                            DEBT_ASSET_PROPERTY: (datas[i].DEBT_COLUMN === '非流动负债：' || datas[i].DEBT_COLUMN === '流动负债：' || datas[i].DEBT_COLUMN === '...:') ? '' : (datas[i].DEBT_ASSET_PROPERTY !== '' && datas[i].DEBT_ASSET_PROPERTY) ? datas[i].DEBT_ASSET_PROPERTY : '',
                            DEBT_ENDING_BALANCE: (datas[i].DEBT_COLUMN === '非流动负债：' || datas[i].DEBT_COLUMN === '流动负债：' || datas[i].DEBT_COLUMN === '...:') ? '' : (datas[i].DEBT_ENDING_BALANCE !== '' && datas[i].DEBT_ENDING_BALANCE) ? datas[i].DEBT_ENDING_BALANCE : '',
                            ENTERZICHANFUZHAI_ID: datas[i].ENTERZICHANFUZHAI_ID
                        })
                    }
                } else {
                    this.$message.error('系统异常，获取数据失败，请稍后重试！')
                }
            })
        },
        // 保存
        onSave () {
            if (!this.isEdit) {
                this.$message.info('请先点击编辑后才能进行保存！')
                return false
            }
            let DATA_IDS = '', DATA_VALUES1 = '', DATA_VALUES2 = '', DATA_VALUES3 = '', DATA_VALUES4 = ''
            for (let i in this.assetList) {
                DATA_IDS += this.assetList[i].ENTERZICHANFUZHAI_ID + ','
                if (this.assetList[i].ASSET_ENDING_BALANCE !== '') {
                    DATA_VALUES1 += this.assetList[i].ASSET_ENDING_BALANCE + ','
                } else {
                    DATA_VALUES1 += '0,'
                }
                if (this.assetList[i].ASSET_BEGINNING_BALANCE !== '') {
                    DATA_VALUES2 += this.assetList[i].ASSET_BEGINNING_BALANCE + ','
                } else {
                    DATA_VALUES2 += '0,'
                }
            }
            for (let i in this.debtList) {
                if (this.debtList[i].DEBT_ASSET_PROPERTY !== '') {
                    DATA_VALUES3 += this.debtList[i].DEBT_ASSET_PROPERTY + ','
                } else {
                    DATA_VALUES3 += '0,'
                }
                if (this.debtList[i].DEBT_ENDING_BALANCE !== '') {
                    DATA_VALUES4 += this.debtList[i].DEBT_ENDING_BALANCE + ','
                } else {
                    DATA_VALUES4 += '0,'
                }
            }
            DATA_IDS = DATA_IDS.substring(0, DATA_IDS.length - 1)
            DATA_VALUES1 = DATA_VALUES1.substring(0, DATA_VALUES1.length - 1)
            DATA_VALUES2 = DATA_VALUES2.substring(0, DATA_VALUES2.length - 1)
            DATA_VALUES3 = DATA_VALUES3.substring(0, DATA_VALUES3.length - 1)
            DATA_VALUES4 = DATA_VALUES4.substring(0, DATA_VALUES4.length - 1)
            this.$store.dispatch('saveZCFZ', {
                DATA_IDS: DATA_IDS,
                DATA_VALUES1: DATA_VALUES1,
                DATA_VALUES2: DATA_VALUES2,
                DATA_VALUES3: DATA_VALUES3,
                DATA_VALUES4: DATA_VALUES4,
                tm: new Date().getTime(0)
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('保存成功！')
                    this.$store.commit('SET_ISEDIT', false)
                } else {
                    this.$message.error('系统异常，保存失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getZCFZList(this.y)
    }
}
</script>
<style lang="less" scoped>
    ::v-deep(.ant-form-item){
        margin-bottom: 5px !important;

    }
::v-deep(.ant-form-item-label) {
  width: 50%;
  text-align:left;

}
.con-box{
    padding: 15px;
    background-color: @color-ff;
    box-shadow: 0px 0px 10px #929292;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;
    .title{
        font-size: @font-lg;
        padding: 0px 10px;
        padding-bottom: 15px;
        border-bottom: @border-base;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            margin-bottom: 0;
            width: 10%;
            text-align: center;
        }
    }
    .content-box{
        margin-top: 10px;
        font-size: 16px;
        padding: 0 10px;
        display: flex;
    }
}
    /deep/.ant-input-number{
        width: 100% !important;
    }

</style>
